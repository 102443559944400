:root {
  --black: black;
  --dark-grey: rgba(60, 60, 60, 0.8);
  --white: white;

  --tile-size: 1rem;
  --scale: 1;
  --unit: calc(var(--tile-size) * var(--scale));

  --screen-width: calc(60 * var(--tile-size));
  --screen-height: calc(40 * var(--tile-size));
}

body {
  background-color: var(--black);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
